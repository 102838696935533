html{
	height: 100%;
	background-color: #FFFFFF;
}

body {
	height: 100%;
	margin: 0;
	font-family: 'Font2R';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root{
	height: 100%;
}

@font-face {
	font-family: 'Font2R';
	src: local( 'Font2R'), url(../fonts/Font2R.ttf) format('truetype');
}

@font-face {
	font-family: 'DM Serif Display Italic';
	src: local( 'DM Serif Display Italic'), url(../fonts/DMSerifDisplay-Italic.ttf) format('truetype');
}
@font-face {
	font-family: 'DM Serif Display Regular';
	src: local( 'DM Serif Display Regular'), url(../fonts/DMSerifDisplay-Regular.ttf) format('truetype');
}